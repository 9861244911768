<template>
	<div>
		<div v-if="checking_infos">
            <LoadingSpinner class="col-12" />
        </div>
        <div v-else>
            <h3>{{ $t('marketplace.completer_informations_obligatoire') }}</h3>

            <div class="row">
                <div class="col-12" v-for="(infos, key) in datas_required" v-bind:key="key">

                    <div class="form-group">
                        <label for="">{{ $t('marketplace.user_infos.' + key) }}</label>
                        <component 
                            :is="infos"
                            :key="key"
                            :q_id="key"
                            :placeholder="getTrad('marketplace.user_infos.' + key)"
                            :answer.sync="form[key]"
                            v-model="form[key]"
                        >
                        </component>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button class="btn btn-primary" @click="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />{{ $t('global.sauvegarder') }}</button>
                </div>
            </div>
        </div>
	</div>
</template>
<script type="text/javascript">
    import Invoice from '@/mixins/Invoice.js'
    import Marketplace from '@/mixins/Marketplace.js'
    import Navigation from '@/mixins/Navigation.js'
    import Vue from 'vue'
    import Countries from 'GroomyRoot/assets/lang/countries/countries'

	export default {
        name: "PayInvoicesList",
        mixins:[Marketplace, Invoice, Navigation],
        props:{
        	callback: {
        		type: Function,
        		default: () => ({})
        	}
        },
		data () {
			return {
                working: false,
                processing: false,
                checking_infos: true,
                require_infos: false,
                errors: [],
                datas_required: [],
                form_message: '',
                form: {
                	birthday: new Date(),
                	country: 75,
                	ad1: null,
                	code_postal: null,
                	ville: null,
                	name: null,
                	firstname: null,
                },
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
                this.working = true

                await this.checkUserInfos()

				this.working = false
            },

            async checkUserInfos() {                
                this.checkRequiredUserInfos()
                .then(r => {
                    if(r.code_retour == "PM")
                    {
                        this.checking_infos = false
                    	//Affichage du formulaire pour completer les infos du user
                    	this.require_infos = true
                    	this.datas_required = r.message
                    }
                    else
                    {
                        this.checking_infos = false
                        this.callback()
                    }
                })
                .catch(e => {

                })
            },

            async checkForm(){
                let values = {}

                if(!this.processing) {
                    this.processing = true
                    Object.keys(this.datas_required).forEach((item) => {

                        if(this.datas_required[item] == "inputText") {
                            if(this.form[item].val != null && this.form[item].val != "") {
                                if(item == "ad1" && this.form[item].val.indexOf('@') > -1) {
                                    //
                                }
                                else  {
                                    values[item] = this.form[item].val
                                }
                            }
                        }
                        else if(this.datas_required[item] == "date") {
                            values[item] = this.form[item]
                        }
                        else if(this.datas_required[item] == "countryInput") {
                            values[item] = Countries[Vue.i18n.locale()].iso_3[this.form[item]]
                        }
                    })

                    await this.saveRequiredUserInfos(values)
                    await this.checkUserInfos()
                    
                    this.processing = false
                }
            }
        },
        
        computed: {
            
        },
        watch: {

        },

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            date : () => import('GroomyRoot/components/Utils/EDatepicker'),
			inputText : () => import('GroomyRoot/components/Utils/EInput'),
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),

		}
	}
</script>
